<script>
export default {
  name: 'BaseSliderItem',
  inject: ['baseSlider'],
  props: {
    value: {
      type: [Number, String],
      default: 0,
    },
  },
  computed: {
    isActive() {
      return this.baseSlider.internalValue === this.value
    },
  },
  created() {
    this.baseSlider.register(this)
  },

  beforeDestroy() {
    this.baseSlider.unregister(this)
  },
  methods: {
    toggle() {
      this.baseSlider.toggleItemValue(this.value)
    },
  },
  render() {
    if (!this.$scopedSlots.default && this.value === undefined) {
      return null
    }

    let element

    if (this.$scopedSlots.default) {
      element = this.$scopedSlots.default({
        active: this.isActive,
        toggle: this.toggle,
      })
    }

    if (Array.isArray(element) && element.length === 1) {
      element = element[0]
    }

    return element
  },
}
</script>
