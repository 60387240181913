<script>
import { h } from '@nuxtjs/composition-api'
import ProductSlider from '~/components/product/ProductSlider'
import {
  getCmsSlotConfigProperty,
  isCmsSlotActive,
  isCmsSlotConfigTrue,
} from '~/helpers'
import { useUiEvent } from '~/composables'
import CmsUtilLink from '~/components/cms/util/CmsUtilLink'

export default {
  name: 'CmsElementProductSlider',
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { root }) {
    const uiEventClick = useUiEvent(root, 'cross_selling', 'cms')
    const content = props.content
    const title = getCmsSlotConfigProperty(content, 'title') || undefined
    const anchor = getCmsSlotConfigProperty(content, 'anchor') || undefined

    const genSlider = (id) => {
      return h(ProductSlider, {
        props: {
          products: content.data.products || [],
          smallWidth: isCmsSlotConfigTrue(content, 'smallWidth'),
          largeWidth: isCmsSlotConfigTrue(content, 'largeWidth'),
          disableOverlay: isCmsSlotConfigTrue(content, 'disableOverlay'),
          overlayClass:
            getCmsSlotConfigProperty(content, 'overlayClass') || undefined,
          cardColor:
            getCmsSlotConfigProperty(content, 'cardColor') || undefined,
          lastItemActive: isCmsSlotConfigTrue(content, 'lastElementActive'),
          lastItemUrl:
            getCmsSlotConfigProperty(content, 'lastElementUrl') || undefined,
          lastItemBgColor:
            getCmsSlotConfigProperty(content, 'lastElementBgColor') ||
            undefined,
          lastItemColor:
            getCmsSlotConfigProperty(content, 'lastElementTextColor') ||
            undefined,
          lastItemText:
            getCmsSlotConfigProperty(content, 'lastElementText') || undefined,
          useBsProductExtension: !!content.data.streamId,
          useListingPrice: !!content.data.streamId,
        },
        on: {
          'click:product'() {
            uiEventClick()
          },
        },
        attrs: {
          id,
        },
      })
    }

    const genTitle = (id) => {
      return h(
        CmsUtilLink,
        {
          staticClass:
            'mb-8 mb-md-13 text-h4 text-md-h2 text-uppercase text-center d-block text-decoration-none grey-darken-4--text',
          props: {
            wrapped: 'div',
            url: getCmsSlotConfigProperty(content, 'titleUrl') || undefined,
          },
          attrs: {
            id,
          },
        },
        [title]
      )
    }

    if (!isCmsSlotActive(content)) {
      return () => null
    }

    return () =>
      h(
        'lazy-hydrate',
        {
          props: {
            whenVisible: true,
          },
        },
        [title ? h('div', [genTitle(anchor), genSlider()]) : genSlider(anchor)]
      )
  },
}
</script>
