<template>
  <div :class="classes">
    <base-slider>
      <base-slider-item v-for="(item, index) in items" :key="item.id">
        <product-cross-selling-card
          :url="item.url"
          :cover="item.cover"
          :title="item.title"
          :sub-title="item.subTitle"
          :price="item.price"
          :uvp-price="item.uvpPrice"
          :rating-average="item.ratingAverage"
          :card-color="cardColor"
          :image-init-width="250"
          @click.native="handleClick(item, index)"
        ></product-cross-selling-card>
      </base-slider-item>
      <base-slider-item v-if="lastItem">
        <cms-util-link
          :url="lastItem.url"
          :style="lastItem.style"
          wrapped="div"
          class="
            product-slider__last-element
            d-flex
            justify-center
            align-center
            flex-column
            text-decoration-none
          "
        >
          <base-icon
            style="color: inherit"
            mdi="arrow-right-circle"
          ></base-icon>
          <div class="text-h5 font-weight-bold text-center">
            {{ lastItem.text }}
          </div>
        </cms-util-link>
      </base-slider-item>
    </base-slider>

    <div
      v-if="!disableOverlay"
      :class="overlayClass"
      class="product-slider__overlay"
    ></div>
  </div>
</template>

<script>
import {
  getProductMainTitle,
  getProductParentCoverMedia,
  getProductParentUrl,
  getProductRatingAverage,
  getProductRealPrice,
  getProductRealUvpPrice,
  getProductSubTitle,
  getProductUrl,
} from '~/helpers'
import BaseSlider from '~/components/base/slider/BaseSlider'
import BaseSliderItem from '~/components/base/slider/BaseSliderItem'
import ProductCrossSellingCard from '~/components/product/cross-selling/ProductCrossSellingCard'
import BaseIcon from '~/components/base/icon/BaseIcon'
import { getProductListingRealPrice } from '~/helpers/product/getProductListingRealPrice'
import { getProductListingRealUvpPrice } from '~/helpers/product/getProductListingRealUvpPrice'
export default {
  name: 'ProductSlider',
  components: {
    BaseIcon,
    ProductCrossSellingCard,
    BaseSliderItem,
    BaseSlider,
  },
  props: {
    products: {
      type: Array,
      default: () => [],
    },
    smallWidth: {
      type: Boolean,
      default: false,
    },
    largeWidth: {
      type: Boolean,
      default: false,
    },
    disableOverlay: {
      type: Boolean,
      default: false,
    },
    cardColor: {
      type: String,
      default: 'white',
    },
    overlayClass: {
      type: String,
      default: 'green-pastel-light',
    },
    lastItemActive: {
      type: Boolean,
      default: false,
    },
    lastItemUrl: {
      type: String,
      default: undefined,
    },
    lastItemBgColor: {
      type: String,
      default: '#EDE2F4',
    },
    lastItemColor: {
      type: String,
      default: '#575756',
    },
    lastItemText: {
      type: String,
      default: undefined,
    },
    useBsProductExtension: {
      type: Boolean,
      default: false,
    },
    useListingPrice: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return {
        'product-slider': true,
        'product-slider--small-width-responsiv': this.smallWidth,
        'product-slider--large-width-responsiv': this.largeWidth,
      }
    },
    items() {
      return this.products?.map((product) => {
        return {
          id: product.id,
          alt: null,
          title: getProductMainTitle(product),
          subTitle: getProductSubTitle(product),
          url: this.useBsProductExtension
            ? getProductParentUrl(product)
            : getProductUrl(product),
          cover: this.useBsProductExtension
            ? getProductParentCoverMedia(product)
            : product.cover?.media,
          uvpPrice: this.useListingPrice
            ? getProductListingRealUvpPrice(product)
            : getProductRealUvpPrice(product),
          price: this.useListingPrice
            ? getProductListingRealPrice(product)
            : getProductRealPrice(product),
          ratingAverage: getProductRatingAverage(product),
        }
      })
    },
    lastItem() {
      if (!this.lastItemActive) {
        return null
      }

      return {
        url: this.lastItemUrl,
        text: this.lastItemText || this.$t('commons.moreProducts'),
        style: {
          backgroundColor: this.lastItemBgColor,
          color: this.lastItemColor,
        },
      }
    },
  },
  methods: {
    handleClick(item, index) {
      this.$emit('click:product', item)
      this.$emit('select_item', { item, index })
    },
  },
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';

.product-slider {
  position: relative;
  padding-bottom: 28px;

  .base-slider {
    z-index: 1;
  }

  .base-slider__items > .product-cross-selling-card:not(:last-child) {
    margin-right: 12px;
  }

  .product-slider__overlay {
    height: 170px;
    top: 141px;
    width: 100vw;
    position: absolute;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
  }

  &--small-width-responsiv {
    .product-cross-selling-card,
    .product-slider__last-element {
      width: 140px;

      .v-icon__svg {
        height: 60px;
        width: 60px;
      }

      @media #{map-get($display-breakpoints, 'sm-and-up')} {
        width: 150px;

        .product-cross-selling-card__rating,
        .product-cross-selling-card__title,
        .product-cross-selling-card__subtitle {
          display: none;
        }
      }
    }

    .base-slider {
      width: 90%;
      margin: 0 auto;
    }

    .product-slider__overlay {
      height: 150px;
      top: 118px;
      width: 100%;
      position: absolute;
      left: 0;
      right: 0;
      margin-left: 0;
      margin-right: 0;

      @media #{map-get($display-breakpoints, 'xs-only')} {
        height: 170px;
        top: 141px;
      }
    }
  }

  &--large-width-responsiv {
    .product-cross-selling-card,
    .product-slider__last-element {
      width: 140px;

      .v-icon__svg {
        height: 60px;
        width: 60px;
      }

      @media #{map-get($display-breakpoints, 'sm-and-up')} {
        width: 238px;

        .base-slider__items > .product-cross-selling-card:not(:last-child) {
          margin-right: 20px;
        }

        .v-icon__svg {
          height: 70px;
          width: 70px;
        }
      }
    }

    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      padding-bottom: 44px;

      .base-slider__items > .product-cross-selling-card:not(:last-child) {
        margin-right: 20px;
      }

      .product-slider__overlay {
        height: 200px;
        top: 255px;
      }
    }
  }
}
</style>
