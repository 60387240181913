<template>
  <div
    :class="classes"
    @touchstart.stop=""
    @touchend.stop=""
    @touchmove.stop=""
  >
    <div
      ref="container"
      v-scroll.self="onScroll"
      :class="itemsClass"
      class="base-slider__items"
    >
      <slot></slot>
    </div>
    <div
      v-show="shouldHidePrevButton"
      class="base-slider__btn base-slider__btn--prev"
      role="button"
      @click.stop="scrollTo(false)"
    >
      <base-icon mdi="chevron-left"></base-icon>
    </div>
    <div
      v-show="shouldHideNextButton"
      class="base-slider__btn base-slider__btn--next"
      role="button"
      @click.stop="scrollTo"
    >
      <base-icon mdi="chevron-right"></base-icon>
    </div>
  </div>
</template>

<script>
import BaseIcon from '~/components/base/icon/BaseIcon'
export default {
  name: 'BaseSlider',
  components: { BaseIcon },
  provide() {
    return {
      baseSlider: this,
    }
  },
  props: {
    value: {
      type: [String, Number],
      default: undefined,
    },
    smallArrow: {
      type: Boolean,
      default: false,
    },
    hideArrowOnMobile: {
      type: Boolean,
      default: false,
    },
    itemsClass: {
      type: [Array, String],
      default: '',
    },
    centerActive: {
      type: Boolean,
      default: false,
    },
    vertical: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      internalLazyValue: this.value,
      items: [],
      shouldHidePrevButton: false,
      shouldHideNextButton: true,
    }
  },
  computed: {
    classes() {
      return {
        'base-slider': true,
        'base-slider--vertical': this.vertical,
        'base-slider--arrow-small': this.smallArrow,
        'base-slider--arrow-hide-mobile': this.hideArrowOnMobile,
      }
    },
    internalValue: {
      get() {
        return this.internalLazyValue
      },
      set(val) {
        if (val === this.internalLazyValue) return

        this.internalLazyValue = val

        this.$emit('input', val)
      },
    },
  },
  watch: {
    value(val) {
      this.internalLazyValue = val
    },
    internalValue() {
      this.scrollToSelectedItem()
    },
    items() {
      this.$nextTick(() => {
        this.scrollToSelectedItem(false)
      })
    },
  },
  mounted() {
    this.scrollToSelectedItem(false)
    this.onScroll()
  },
  methods: {
    onScroll() {
      const {
        scrollLeftVerticalTop,
        clientWidthVerticalHeight,
        scrollWidthVerticalHeight,
      } = this.getContainerRect()

      this.shouldHidePrevButton = scrollLeftVerticalTop > 0
      this.shouldHideNextButton =
        scrollLeftVerticalTop + clientWidthVerticalHeight <
        scrollWidthVerticalHeight
    },
    scroll(left = 0, top = 0, smooth = true) {
      this.$refs.container.scroll({
        top,
        left,
        behavior: smooth ? 'smooth' : 'auto',
      })
    },
    getContainerRect() {
      const container = this.$refs.container

      return {
        clientWidthVerticalHeight: this.vertical
          ? container.clientHeight
          : container.clientWidth,
        scrollLeftVerticalTop: this.vertical
          ? container.scrollTop
          : container.scrollLeft,
        scrollWidthVerticalHeight: this.vertical
          ? container.scrollHeight
          : container.scrollWidth,
      }
    },
    scrollTo(next = true) {
      const {
        scrollLeftVerticalTop,
        clientWidthVerticalHeight,
        scrollWidthVerticalHeight,
      } = this.getContainerRect()

      const calc =
        scrollLeftVerticalTop +
        (next ? clientWidthVerticalHeight : -clientWidthVerticalHeight)

      const left = this.vertical ? 0 : calc
      const top = this.vertical ? Math.min(calc, scrollWidthVerticalHeight) : 0

      this.scroll(left, top)
    },
    scrollToSelectedItem(smooth = true) {
      if (!this.centerActive) {
        return
      }

      const item = this.items.find((item) => item.value === this.internalValue)

      if (!item) {
        return
      }

      const { clientWidthVerticalHeight, scrollLeftVerticalTop } =
        this.getContainerRect()
      const elClientRect = item.$el.getBoundingClientRect()
      const containerClientRect = this.$refs.container.getBoundingClientRect()

      const itemLeft = this.vertical ? elClientRect.top : elClientRect.left
      const selfLeft = this.vertical
        ? containerClientRect.top
        : containerClientRect.left

      const calc =
        scrollLeftVerticalTop +
        (itemLeft - selfLeft - clientWidthVerticalHeight / 2)

      this.scroll(this.vertical ? 0 : calc, this.vertical ? calc : 0, smooth)
    },
    toggleItemValue(value) {
      this.internalValue = value
    },
    register(item) {
      this.items.push(item)
    },
    unregister(item) {
      if (this._isDestroyed) return

      const index = this.items.indexOf(item)

      this.items.splice(index, 1)
    },
  },
}
</script>
