<template>
  <nuxt-link
    class="product-cross-selling-card"
    :class="cardColor"
    :to="url"
    v-on="$listeners"
  >
    <product-image
      :media="cover"
      gradient
      :init-width="imageInitWidth"
    ></product-image>
    <div class="pa-2">
      <div
        style="height: 16px"
        class="text-center mb-2 product-cross-selling-card__rating"
        :class="reviewClass"
      >
        <base-product-rating
          v-if="ratingAverage"
          style="line-height: 16px"
          :value="ratingAverage"
          x-small
        ></base-product-rating>
      </div>
      <div
        :class="titleClass"
        class="text-h6 text-truncate product-cross-selling-card__title"
      >
        {{ title }}
      </div>
      <div
        :class="titleClass"
        class="
          text-body-4 text-truncate
          mt-0
          mb-1
          product-cross-selling-card__subtitle
        "
      >
        {{ subTitle }}
      </div>

      <span class="text-price-3 pr-2">{{ price | price }}</span>
      <span v-if="uvpPrice && price < uvpPrice" class="text-price-discount-3">{{
        uvpPrice | price
      }}</span>
    </div>
  </nuxt-link>
</template>

<script>
import ProductImage from '~/components/product/ProductImage'
import BaseProductRating from '~/components/base/product/rating/BaseProductRating'

export default {
  name: 'ProductCrossSellingCard',
  components: {
    BaseProductRating,
    ProductImage,
  },

  props: {
    reviewClass: {
      type: [String, Array],
      default: '',
    },
    titleClass: {
      type: [String, Array],
      default: '',
    },
    url: {
      type: String,
      default: null,
    },
    cover: {
      type: Object,
      default: null,
    },
    width: {
      type: [String, Number],
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    subTitle: {
      type: String,
      default: null,
    },
    price: {
      type: Number,
      default: undefined,
    },
    uvpPrice: {
      type: Number,
      default: undefined,
    },
    ratingAverage: {
      type: Number,
      default: 0,
    },
    cardColor: {
      type: String,
      default: 'white',
    },
    imageInitWidth: {
      type: Number,
      default: undefined,
    },
  },
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';

.product-cross-selling-card {
  text-decoration: none;
  color: map-get($shades, 'grey-darken-4');
}
</style>
